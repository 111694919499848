export default {
	cinema: {
		address: 'ул. Объездная, 30',
		place: null,
		phoneNumbers: ['+7 (4832) 32-20-20'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/panoramakino',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: 'https://apps.apple.com/ru/app/кинотеатр-панорама/id1465257109?l=en',
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.panorama',
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CBFpQYaWGB',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
